import Cookies from "js-cookie";
import actions from "./actions";
import axios from "axios";
import {
    setItem,
    removeItem,
    getItem,
    addVariantListInIndexdb,
    addVariantGroupListInIndexdb,
    addProductAddonListInIndexdb,
    addProductAddonGroupListInIndexdb,
} from "../../utility/localStorageControl";

import { DarkModeAvailable } from "../preference/actionCreator";
import { getAllRegisterList } from "../register/actionCreator";
import { getApplicationById } from "../applicatons/actionCreator";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
    loginBegin,
    loginSuccess,
    loginErr,
    logoutBegin,
    logoutSuccess,
    logoutErr,
    resetPasswordSuccess,
    registerBegin,
    registerErr,
    registerSuccess,
    ForgotpinAdd,
    ForgotpinAddErr,
    offlineModeValue,
    LocalTableArray,
} = actions;

const getDeviceId = () => {
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    return uid;
};

const checkLocalDataStore = async (userId) => {
    let userDeviceId = `${userId}_${getDeviceId()}`;
    let localData = await DataService.get(`${API.auth.localStorageStore}?device_id=${userDeviceId}`);
    if (localData && localData.data.data[0]) {
        setItem("userDeviceId", localData.data.data[0]._id);
        localData.data.data[0].allLocalData.map((val) => {
            setItem(val.key, val.value);
        });
    }
};

const storePrintingPrefrence = (printingPrefrnce) => {
    if (printingPrefrnce.hasOwnProperty("print_order_ticket_KOT_number_in_the_receipt")) {
        setItem("print_order_tiket_number", printingPrefrnce.print_order_ticket_KOT_number_in_the_receipt);
    }
    if (printingPrefrnce.hasOwnProperty("print_receipt_first_then_accept_payment")) {
        setItem("print_receipt_first", printingPrefrnce.print_receipt_first_then_accept_payment);
    }
    if (printingPrefrnce.hasOwnProperty("do_not_print_tax_rates_against_each_product")) {
        setItem("do_not_each_tax", printingPrefrnce.do_not_print_tax_rates_against_each_product);
    }

    if (printingPrefrnce.hasOwnProperty("print_product_notes_in_the_receipt")) {
        setItem("print_receipt_product_notes", printingPrefrnce.print_product_notes_in_the_receipt);
    }
    if (printingPrefrnce.hasOwnProperty("print_receipt_first_then_accept_payment")) {
        setItem("print_receipt_first_then_accept_payment", printingPrefrnce.print_receipt_first_then_accept_payment);
    }
    if (printingPrefrnce.hasOwnProperty("print_register_name_on_receipt")) {
        setItem("print_register_name", printingPrefrnce.print_register_name_on_receipt);
    }
    if (printingPrefrnce.hasOwnProperty("print_item_price_on_receipt")) {
        setItem("print_item_price", printingPrefrnce.print_item_price_on_receipt);
    }
    if (printingPrefrnce.hasOwnProperty("print_server_copy_of_order_ticket_KOT")) {
        setItem("print_server_copy", printingPrefrnce.print_server_copy_of_order_ticket_KOT);
    }
    if (printingPrefrnce.hasOwnProperty("print_settlement_bill_after_accepting_payment")) {
        setItem("print_settlement_paymnet", printingPrefrnce.print_settlement_bill_after_accepting_payment);
    }
    if (printingPrefrnce.hasOwnProperty("do_not_print_copy_of_receipt_and_order_tickets")) {
        setItem("print_copy_of_receipt_order_ticket", printingPrefrnce.do_not_print_copy_of_receipt_and_order_tickets);
    }
};

const storePermissionPrefrence = (permissionPrefrence) => {
    if (permissionPrefrence.hasOwnProperty("allow_cashiers_to_offer_discounts")) {
        setItem("allow_cashier_to_discount", permissionPrefrence.allow_cashiers_to_offer_discounts);
    }
    if (permissionPrefrence.hasOwnProperty("allow_managers_to_change_email_address_while_requesting_reports")) {
        setItem("allow_manager_to_change_email", permissionPrefrence.allow_managers_to_change_email_address_while_requesting_reports);
    }
    if (permissionPrefrence.hasOwnProperty("hide_the_shift_summary_link_in_lock_screen")) {
        setItem("hide_the_shift_summary_link_in_lock_screen", permissionPrefrence.hide_the_shift_summary_link_in_lock_screen);
    }
    if (permissionPrefrence.hasOwnProperty("do_not_allow_manager_to_unlock_cart")) {
        setItem("do_not_allow_manager_to_unlock_cart", permissionPrefrence.do_not_allow_manager_to_unlock_cart);
    }
    if (permissionPrefrence.hasOwnProperty("do_not_allow_manager_to_receipt_cancel")) {
        setItem("do_not_allow_manager_to_receipt_cancel", permissionPrefrence.do_not_allow_manager_to_receipt_cancel);
    }
    if (permissionPrefrence.hasOwnProperty("do_not_allow_cashier_to_edit_and_cancel_reservation")) {
        setItem("do_not_allow_cashier_to_edit_and_cancel_reservation", permissionPrefrence.do_not_allow_cashier_to_edit_and_cancel_reservation);
    }
};
const login = (payloads, history, id) => {
    return async (dispatch) => {
        try {
            dispatch(loginBegin());
            console.log("asdasdadasdasdasda", id);

            let loggedIn;
            if (id) {
                loggedIn = await DataService.get(`${API.auth.userLogin}/${id}`);
            } else {
                loggedIn = await DataService.post(API.auth.login, payloads);
            }
            if (!loggedIn.data.error) {
                if (loggedIn.data.data.role === "restaurant") {
                    setItem("active_cart", null);
                    const res = await axios({
                        method: "GET",
                        url: `https://api.posease.com/api/localStorage/setup/${loggedIn.data.data._id}`,
                        headers: {
                            Authorization: `Bearer ${loggedIn.data.data.token}`,
                            "Content-Type": "application/json",
                        },
                    });
                    res.data.data.register = res.data.data.register.map((val) => {
                        val.table_array = val.table_array.map((table) => {
                            delete table["qrCodeUrl"];
                            return table;
                        });

                        if (val.hotelDetalis && val.hotelDetalis.rooms && val.hotelDetalis.rooms.length) {
                            val.hotelDetalis.rooms = val.hotelDetalis.rooms.map((table) => {
                                delete table["qrCodeUrl"];
                                return table;
                            });
                        }
                        return val;
                    });
                    setItem("access_token", loggedIn.data.data.token);

                    if (res.data.data && res.data.data.application && res.data.data.application.length) {
                        setItem("application", res.data.data.application[0]);
                        delete res.data.data.application;
                    }
                    if (res.data.data.recent_activity.length > 0) {
                        setItem("shfitOpenedTS", res.data.data.recent_activity[0].action);
                    } else {
                        setItem("shfitOpenedTS", "close");
                    }

                    if (res.data.data.preferences?.length > 0 && res.data.data.preferences[0].permission_preferences) {
                        storePermissionPrefrence(res.data.data.preferences[0].permission_preferences);
                    }
                    if (res.data.data.preferences?.length > 0 && res.data.data.preferences[0].printing_preferences) {
                        storePrintingPrefrence(res.data.data.preferences[0].printing_preferences);
                    }
                    if (res.data.data.preferences?.length > 0 && res.data.data.preferences[0].hasOwnProperty("selling_preferences")) {
                        let preferences = res.data.data.preferences?.length > 0 ? res.data.data.preferences[0].selling_preferences : {};
                        if (preferences.hasOwnProperty("enforce_sequential_local_receipt_numbers")) {
                            setItem("localReceipt", preferences.enforce_sequential_local_receipt_numbers);
                            if (preferences.enforce_sequential_local_receipt_numbers) {
                                setItem("isStartSellingFromThisDevice", true);
                            }
                        }
                        if (preferences.hasOwnProperty("do_not_round_off_sale_total")) {
                            setItem("doNotRoundOff", preferences.do_not_round_off_sale_total);
                        }
                        if (preferences.hasOwnProperty("display_items_in_sell_screen_as_a_list_instead_of_grid")) {
                            setItem("listView", preferences.display_items_in_sell_screen_as_a_list_instead_of_grid);
                        }
                        if (preferences.hasOwnProperty("enable_order_ticket_kot_genration")) {
                            setItem("orderTicketButton", preferences.enable_order_ticket_kot_genration);
                        }
                        if (preferences.hasOwnProperty("do_not_kot_while_charge")) {
                            setItem("do_not_kot_while_charge", preferences.do_not_kot_while_charge);
                        }
                        if (preferences.hasOwnProperty("enable_quick_billing")) {
                            setItem("enable_quick_billing", preferences.enable_quick_billing);
                        }
                        if (preferences.hasOwnProperty("hide_quantity_increase_decrease_buttons")) {
                            setItem("hide_quantity_increase_decrease_buttons", preferences.hide_quantity_increase_decrease_buttons);
                        }
                        if (preferences.hasOwnProperty("hide_all_and_top_categories")) {
                            setItem("hideAllAndTop", preferences.hide_all_and_top_categories);
                        }
                        if (preferences.hasOwnProperty("enforce_customer_mobile_number")) {
                            setItem("enforce_customer_mobile_number", preferences.enforce_customer_mobile_number);
                        }

                        setItem("enable_billing_only_when_shift_is_opened", preferences.enable_billing_only_when_shift_is_opened);

                        setItem("dark_mode", preferences.dark_mode);
                        dispatch(DarkModeAvailable(preferences.dark_mode));
                    }

                    if (res.data.data.preferences && res.data.data.preferences[0] && res.data.data.preferences[0]._id) {
                        setItem("prefernce_id", res.data.data.preferences[0]._id);
                    }

                    let totalUserlist = res.data.data.userList;
                    let app_userList = [];
                    let waiterList = [];
                    let kitchen_user = [];
                    let cashierList = [];
                    let housekeepingList = [];
                    if (totalUserlist && totalUserlist.length > 0) {
                        app_userList = totalUserlist.filter((val) => val.role == "app_user");
                        waiterList = totalUserlist.filter((val) => val.role == "waiter");
                        kitchen_user = totalUserlist.filter((val) => val.role == "kitchen_user");
                        cashierList = totalUserlist.filter((val) => val.role == "cashier");
                        housekeepingList = totalUserlist.filter((val) => val.role == "housekeeping");
                    }

                    let customFieldList = res.data.data.customFields;
                    let pattycashCustomFiled = [];
                    let addtionCustomFiled = [];
                    let paymentTypeCustomFiled = [];
                    let tagCustomeFiled = [];
                    if (customFieldList && customFieldList.length > 0) {
                        pattycashCustomFiled = customFieldList.filter((val) => val.type == "petty_cash_category");
                        addtionCustomFiled = customFieldList.filter((val) => val.type == "additional_detail");
                        tagCustomeFiled = customFieldList.filter((val) => val.type == "tag");
                        paymentTypeCustomFiled = customFieldList.filter((val) => val.type == "payment_type");
                    }
                    res.data.data.userList = {
                        appUserList: app_userList,
                        kitchenUserList: kitchen_user,
                        waiterUserList: waiterList,
                        cashierUserList: cashierList,
                        housekeepingUserList: housekeepingList,
                    };
                    res.data.data.customFields = {
                        patty_cash: pattycashCustomFiled,
                        addtional: addtionCustomFiled,
                        tag: tagCustomeFiled,
                        paymnetType: paymentTypeCustomFiled,
                    };
                    res.data.data.register = res.data.data.register.reverse();
                    res.data.data.setUpPrinter = res.data.data.printerSetupList;

                    if (res.data.data.shopDetails.shop_logo == "" || res.data.data.shopDetails.shop_logo == "false") {
                        if (!res.data.error) {
                            setItem("productList", res.data.data.products);
                            delete res.data.data.products;
                            let varinatList = JSON.stringify(res.data.data.productVariants);
                            addVariantListInIndexdb(varinatList);
                            delete res.data.data.productVariants;
                            let variantGroup = JSON.stringify(res.data.data.productVariantGroups);
                            addVariantGroupListInIndexdb(variantGroup);
                            delete res.data.data.productVariantGroups;
                            let addon = JSON.stringify(res.data.data.productAddon);
                            addProductAddonListInIndexdb(addon);
                            delete res.data.data.productAddon;
                            let addonGroup = JSON.stringify(res.data.data.productAddonGroups);
                            addProductAddonGroupListInIndexdb(addonGroup);
                            delete res.data.data.productAddonGroups;
                            setItem("setupCache", res.data.data);

                            setItem("profile-imge", "");
                            setItem("pendingShiftList", []);
                            setItem("pendingPattyCashEntries", []);

                            if (getItem("LOCAL_STORAGE_CART_KEY_NAME") != null) {
                                setItem("LOCAL_STORAGE_CART_KEY_NAME", getItem("LOCAL_STORAGE_CART_KEY_NAME"));
                                setItem("localUpdate", !getItem("localUpdate"));
                            } else {
                                setItem("LOCAL_STORAGE_CART_KEY_NAME", []);
                                setItem("localUpdate", true);
                            }
                        }
                        if (loggedIn.data.data.role === "restaurant" && loggedIn.data.data.is_shop) {
                            setItem("totalOnlineOrder", []);
                            setItem("email_id", loggedIn.data.data.email);
                            // checkLocalDataStore(loggedIn.data.data._id);
                            history.push("/pin-auth");
                            dispatch(loginSuccess(false));
                            if (id) {
                                return loggedIn.data.data;
                            }
                        } else {
                            history.push("/settings/shop");

                            setItem("totalOnlineOrder", []);
                            setItem("userDetails", loggedIn.data.data);
                            setItem("username", loggedIn.data.data.username);
                            setItem("role", loggedIn.data.data.role);

                            Cookies.set("logedIn", true);
                            dispatch(loginSuccess(true));
                            if (id) {
                                return loggedIn.data.data;
                            }
                            // window.location.reload();
                        }
                    } else {
                        const toDataURL = (url) =>
                            fetch(url)
                                .then((response) => response.blob())
                                .then(
                                    (blob) =>
                                        new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.onloadend = () => resolve(reader.result);
                                            reader.onerror = reject;
                                            reader.readAsDataURL(blob);
                                        })
                                );
                        const dataUrl = await toDataURL(res.data.data.shopDetails.shop_logo);

                        res.data.data.shopDetails.shop_logo = dataUrl;
                        if (!res.data.error) {
                            setItem("productList", res.data.data.products);
                            delete res.data.data.products;
                            let varinatList = JSON.stringify(res.data.data.productVariants);
                            addVariantListInIndexdb(varinatList);
                            delete res.data.data.productVariants;
                            let variantGroup = JSON.stringify(res.data.data.productVariantGroups);
                            addVariantGroupListInIndexdb(variantGroup);
                            delete res.data.data.productVariantGroups;
                            let addon = JSON.stringify(res.data.data.productAddon);
                            addProductAddonListInIndexdb(addon);
                            delete res.data.data.productAddon;
                            let addonGroup = JSON.stringify(res.data.data.productAddonGroups);
                            addProductAddonGroupListInIndexdb(addonGroup);
                            delete res.data.data.productAddonGroups;
                            setItem("setupCache", res.data.data);
                            setItem("profile-imge", "");
                            setItem("pendingShiftList", []);
                            setItem("pendingPattyCashEntries", []);
                        }

                        if (loggedIn.data.data.role === "restaurant" && loggedIn.data.data.is_shop) {
                            setItem("totalOnlineOrder", []);
                            setItem("email_id", loggedIn.data.data.email);
                            // checkLocalDataStore(loggedIn.data.data._id);
                            history.push("/pin-auth");
                            dispatch(loginSuccess(false));
                            if (id) {
                                return loggedIn.data.data;
                            }
                        } else {
                            setItem("totalOnlineOrder", []);
                            setItem("userDetails", loggedIn.data.data);
                            setItem("username", loggedIn.data.data.username);
                            setItem("role", loggedIn.data.data.role);
                            Cookies.set("logedIn", true);
                            dispatch(loginSuccess(loggedIn.data.data));
                            window.location.reload();
                            if (id) {
                                return loggedIn.data.data;
                            }
                        }
                    }
                } else if (loggedIn.data.data.role === "admin") {
                    setItem("access_token", loggedIn.data.data.token);
                    setItem("totalOnlineOrder", []);
                    setItem("userDetails", loggedIn.data.data);
                    setItem("username", loggedIn.data.data.username);
                    setItem("role", loggedIn.data.data.role);
                    Cookies.set("logedIn", true);
                    dispatch(loginSuccess(true));
                    history.push("/admin/user-management");
                    // window.location.reload();
                }
            } else {
                dispatch(loginErr(loggedIn));
                return loggedIn;
            }
        } catch (err) {
            dispatch(loginErr(err));
        }
    };
};

const checkSubScription = () => {
    return async (dispatch) => {
        let curretAppData = await dispatch(
            getApplicationById({
                _id: getItem("setupCache").register.find((val) => val.active)._id,
            })
        );
        if (curretAppData) {
            if (curretAppData.bingage_enable) {
                setItem("bingage_enable", curretAppData.bingage_enable.enable);
            }
            if (curretAppData.create_receipt_while_fullfilling_booking) {
                setItem("create_receipt_while_fullfilling_booking", curretAppData.create_receipt_while_fullfilling_booking.enable);
            }
            if (curretAppData.dyno_api_enable) {
                setItem("dyno_api_enable", curretAppData.dyno_api_enable.enable);
            }
            if (curretAppData.hotel_enable) {
                setItem("hotel_enable", curretAppData.hotel_enable.enable);
            }
            if (curretAppData.pettycash_enable) {
                setItem("pettycash_enable", curretAppData.pettycash_enable.enable);
            }
            if (curretAppData.pricebook_enable) {
                setItem("pricebook_enable", curretAppData.pricebook_enable.enable);
            }
            if (curretAppData.qrCode_enable) {
                setItem("qrCode_enable", curretAppData.qrCode_enable.enable);
            }
            if (curretAppData.waiter_app_enable) {
                setItem("waiter_app_enable", curretAppData.waiter_app_enable.enable);
            }
            if (curretAppData.inventrory_app_enable) {
                setItem("inventrory_app_enable", curretAppData.inventrory_app_enable.enable);
            }
            if (curretAppData.report_analytics) {
                setItem("report_analytics", curretAppData.report_analytics.enable);
            }
            if (curretAppData.rate_app_enable) {
                setItem("rate_app_enable", curretAppData.rate_app_enable.enable);
            }
            if (curretAppData.housekeeping_app_enable) {
                setItem("housekeeping_app_enable", curretAppData.housekeeping_app_enable.enable);
            }
            if (curretAppData.booking_app_enable) {
                setItem("booking_app_enable", curretAppData.booking_app_enable.enable);
            }
        } else {
            setItem("bingage_enable", false);
            setItem("inventrory_app_enable", false);
            setItem("create_receipt_while_fullfilling_booking", false);
            setItem("dyno_api_enable", false);
            setItem("hotel_enable", false);
            setItem("pettycash_enable", false);
            setItem("pricebook_enable", false);
            setItem("qrCode_enable", false);
            setItem("waiter_app_enable", false);
            setItem("report_analytics", false);
            setItem("rate_app_enable", false);
            setItem("housekeeping_app_enable", false);
            setItem("booking_app_enable", false);
        }
        await dispatch(getAllRegisterList("checkReceipt"));
    };
};

const secretPinAuth = (payloads, openBalance, history) => {
    localStorage.removeItem("active_cart");
    return async (dispatch) => {
        try {
            dispatch(loginBegin());
            const loggedInViaSecretPin = await DataService.post(API.auth.secretPinAuth, payloads);

            if (loggedInViaSecretPin.data && openBalance != undefined && !loggedInViaSecretPin.data.error) {
                let openBalanceData = await DataService.post(API.shift.UpdateShift, {
                    action: "open",
                    opening_balance: openBalance,
                    userName: loggedInViaSecretPin.data.data.username,
                    register_id: getItem("setupCache").register.find((val) => val.active)._id,
                    actual_time: new Date(),
                });

                if (getItem("shfitOpenedTS") != null) {
                    setItem("shfitOpenedTS", "open");
                }
            }

            if (!loggedInViaSecretPin.data.error) {
                if (getItem("localReceipt") === true) {
                    setItem("isStartSellingFromThisDevice", true);
                }
                setItem("username", loggedInViaSecretPin.data.data.username);
                setItem("role", loggedInViaSecretPin.data.data.role);
                setItem("userDetails", loggedInViaSecretPin.data.data);
                Cookies.set("logedIn", true);

                dispatch(loginSuccess(true));

                return true;
            } else {
                return dispatch(loginErr(loggedInViaSecretPin));
            }
        } catch (err) {
            let allSetupcache = getItem("setupCache");
            let data = allSetupcache.userList.cashierUserList.find((val) => val.pin == payloads.pin);

            if (data == undefined && allSetupcache.shopDetails.shop_owner_pin == payloads.pin) {
                data = allSetupcache.account;
            }

            if (data) {
                if (openBalance) {
                    let openShiftPending = getItem("pendingShiftList");

                    setItem("pendingShiftList", [
                        ...openShiftPending,
                        {
                            openbalance: openBalance,
                            userName: data.username,
                            register_id: getItem("setupCache").register.find((val) => val.active)._id,
                            actual_time: new Date(),
                        },
                    ]);
                }
                setItem("username", data.username);
                setItem("role", data.role);
                setItem("userDetails", data);

                Cookies.set("logedIn", true);

                return dispatch(loginSuccess(true));
            } else {
                return dispatch(
                    loginErr({
                        data: {
                            data: {},
                            error: true,
                            message: "Make sure PIN / name does not already exist for another user",
                            messageCode: "INVALID_PIN",
                            statusCode: 400,
                        },
                    })
                );
            }
        }
    };
};

const onlineToOpenSihft = async (openBalance, user_name) => {
    return await DataService.post(API.shift.UpdateShift, {
        action: "open",
        opening_balance: openBalance,
        userName: user_name,
        register_id: getItem("setupCache").register.find((val) => val.active)._id,
        actual_time: new Date(),
    });
};
const onlineToClosedShift = async (closedbalance, user_name) => {
    await DataService.post(API.auth.lockRegister, {});
    if (!(closedbalance == "notClose")) {
        return await DataService.post(API.shift.UpdateShift, {
            action: "close",
            closing_balance: closedbalance,
            userName: user_name,
            register_id: getItem("setupCache").register.find((val) => val.active)._id,
            actual_time: new Date(),
        });
    }
};

const getLocalData = () => {
    let allLocalArray = [];
    for (var i = 0; i < localStorage.length; i++) {
        if (
            localStorage.key(i).toString() === "LOCAL_STORAGE_CART_KEY_NAME" ||
            localStorage
                .key(i)
                .toString()
                .search("previous") == 0 ||
            localStorage
                .key(i)
                .toString()
                .search("Bill") == 0 ||
            localStorage
                .key(i)
                .toString()
                .search("Booking") == 0 ||
            localStorage
                .key(i)
                .toString()
                .search("bingage_enable")
        ) {
            allLocalArray.push({
                key: localStorage.key(i),
                value: getItem(localStorage.key(i)),
            });
        }
    }
    return allLocalArray;
};
const logOut = (history, check) => {
    return async (dispatch) => {
        try {
            if (check == "shop") {
                Cookies.set("logedIn", false);
                history.push("/pin-auth");
                dispatch(loginSuccess(false));
                return true;
            }
            getItem("userDetails");
            history.push("/login");
            dispatch(logoutBegin());
            dispatch(loginSuccess(false));
            dispatch(logoutSuccess(null));
            Cookies.remove("logedIn");
            removeItem("userDetails");
            removeItem("email_id");
            removeItem("setupCache");
            removeItem("access_token");
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

const logOutForWebsiteLogin = () => {
    return async (dispatch) => {
        try {
            if (check == "shop") {
                Cookies.set("logedIn", false);
                dispatch(loginSuccess(false));
                return true;
            }
            dispatch(logoutBegin());
            dispatch(loginSuccess(false));
            dispatch(logoutSuccess(null));
            Cookies.remove("logedIn");
            removeItem("userDetails");
            removeItem("email_id");
            removeItem("setupCache");
            removeItem("access_token");
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};
const logOutTransaction = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutBegin());
            dispatch(loginSuccess(false));
            dispatch(logoutSuccess(null));
            Cookies.remove("logedIn");
            removeItem("userDetails");
            removeItem("email_id");
            removeItem("setupCache");
            removeItem("access_token");
            return true;
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

const verifyOtp = (payload) => {
    return async (dispatch) => {
        try {
            let response = await DataService.post(API.auth.verifyOtp, payload);
            if (response && !response.data.error) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};
const LockRegister = (history, finalTotal, user_name) => {
    return async (dispatch) => {
        try {
            const lockRegisterInViaRegister = await DataService.post(API.auth.lockRegister, {});

            if (!lockRegisterInViaRegister.data.error) {
                if (finalTotal !== "") {
                    const CloseShift = await DataService.post(API.shift.UpdateShift, {
                        action: "close",
                        closing_balance: finalTotal,
                        userName: user_name,
                        register_id: getItem("setupCache").register.find((val) => val.active)._id,
                        actual_time: new Date(),
                    });

                    if (!CloseShift.data.error) {
                        setItem("shfitOpenedTS", "close");
                    }
                }

                Cookies.set("logedIn", false);
                history.push("/pin-auth");

                return dispatch(loginSuccess(false));
            } else {
                // dispatch(loginErr(loggedInViaSecretPin));
                return false;
            }
        } catch (err) {
            let getPendingLock = getItem("pendingShiftList");
            setItem("pendingShiftList", [
                ...getPendingLock,
                {
                    closebalance: finalTotal != "" ? finalTotal : "notClose",
                    userName: user_name,
                },
            ]);
            Cookies.set("logedIn", false);
            history.push("/pin-auth");
            return dispatch(loginSuccess(false));
        }
    };
};

const register = (payloads) => {
    return async (dispatch) => {
        try {
            dispatch(registerBegin());
            const registered = await DataService.post(API.auth.register, payloads);

            if (!registered.data.error) {
                return dispatch(registerSuccess(registered.data));
            } else {
                return dispatch(registerErr(registered.data));
            }
        } catch (err) {
            dispatch(registerErr(err));
        }
    };
};

const forgotPassword = (payloads) => {
    return async (dispatch) => {
        try {
            const resetLinkSent = await DataService.post(API.auth.forgotPassword, payloads);
            if (!resetLinkSent.data.error) {
                return dispatch(resetPasswordSuccess(resetLinkSent.data));
            }
        } catch (err) {}
    };
};
const forgotPin = (payloads, Forgotpin_id) => {
    return async (dispatch) => {
        try {
            let getAddedForgotpin = await DataService.post(`${API.auth.forgotPin}`, payloads);
            if (!getAddedForgotpin.data.error) {
                return dispatch(ForgotpinAdd(getAddedForgotpin.data.data));
            } else {
                return dispatch(ForgotpinAddErr(getAddedForgotpin.data.data));
            }
        } catch (err) {
            dispatch(ForgotpinAddErr(err));
        }
    };
};
const waiterTableUpdate = (payloads) => {
    return async (dispatch) => {
        return dispatch(LocalTableArray(payloads));
    };
};

const resetPassword = (payloads, token) => {
    return async (dispatch) => {
        try {
            const resetPassword = await DataService.post(`${API.auth.resetPassword}?token=${token}`, payloads);
            return resetPassword;
        } catch (err) {}
    };
};
const offLineMode = (value) => {
    return offlineModeValue(value);
};

export {
    login,
    logOut,
    forgotPassword,
    resetPassword,
    register,
    LockRegister,
    secretPinAuth,
    forgotPin,
    offLineMode,
    onlineToOpenSihft,
    onlineToClosedShift,
    waiterTableUpdate,
    logOutTransaction,
    checkSubScription,
    verifyOtp,
    logOutForWebsiteLogin,
};
